:root {
    --section1-background: #FFA739;
    --section1-foreground: #422400;
    --section2-background: #FFCB39;
    --section2-foreground: #574000;
}

.bodyheader {
    padding: 10% 5%;
    background-color: #9B2800;
    color: #fff;
}

.bodysection {
    padding: 5% 15px;
    background-color: var(--section1-background);
    color: var(--section1-foreground);
}

.bodysection:nth-child(even) {
    background-color: #FFCB39;
    color: #574000;
}

.bodysection a {
    color: var(--section1-foreground);
    text-decoration: underline;
}
.bodysection a:hover {
    color: var(--section1-foreground);
    text-decoration: none;
}


h1, h2, h3 {
    font-family: 'Caveat', cursive;
}

.section-header {
    font-size: 1.625em;
    line-height: 1.15em;
}

@media screen and (min-width: 600px) {
    .section-header {
        font-size: 2.25em;
        line-height: 1.25em;
    }       
}