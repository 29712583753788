.validationmessage {
    margin: 5px;
    color: #9B2800;
    font-style: italic;
}

.signup-form div {
    position: relative;
    width: fit-content;
    margin: 0 auto;
}

.signup-form i {
    color: #9B2800;
    position: absolute;
    bottom: 5px;
    right: -25px;
}

.signup-form input {
    display: block;
    background: #FFF;
    border: 1px solid var(--section2-foreground);
    border-radius: 0.3em;
    margin: 0.5em auto;
    padding: 0.3em;
    color: var(--section2-foreground);
    font-size: 0.8em;
}

.signup-form button {
    background: #FFF;
    border: 1px solid var(--section2-foreground);
    border-radius: 0.3em;
    margin: 0.5em;
    padding: 0.3em;
    color: var(--section2-foreground);
    font-weight: bold;
    font-size: 0.8em;
    min-width: 10%;
}

.signup-form button:disabled {
    background: var(--section2-foreground);
    color: var(--section2-background);
    text-decoration: line-through;
}
