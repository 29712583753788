nav {
  padding: 1% 0;
  background-color: #FFF;
  width: 100%;
}

nav a {
    display: inline-block;
    margin: 10px;
    color: #9B2800;
    text-decoration: none;
}

nav a:hover {
    color: #C53200;
}

nav .active {
    text-decoration: underline;
}

nav .active:hover {
    color: #C53200;
}