.publicentries-list {
    list-style: none;
    padding: 0;
}

.publicentries-item {
    border: 1px dotted var(--section2-foreground);
    border-radius: 5px;
    margin: 1em auto;
    width: 90%;
    text-align: left;
    padding: 10px;
}

.publicentries-content {
    font-weight: bold;
}

.publicentries-date {
    display: block;
    text-align: right;
    padding: 0 5px;
    font-style: italic;
    font-size: 0.8em;
}