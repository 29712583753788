.header-logo {
    max-height: 40vh;
    max-width: 80%;
    display: inline-block;
    margin: 0;
}

.header-subheader {
    font-size: 2em;
    line-height: 1.25em;
    margin: 10% auto 0 auto;
}

@media screen and (min-width: 600px) {
    .header-subheader {
        font-size: 3em;
        line-height: 1.05em;
    }    
}