.modaloverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(155, 40, 0, 0.80);
}

.modalcontent {
    position: absolute;
    top: 30%;
    left: 40px;
    right: 40px;
    bottom: auto;
    background: #FFF;
    border: 1px solid var(--section1-foreground);
    border-radius: 0.3em;
    color: var(--section1-foreground);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    padding: 20px;
}

.modalbutton {
    display: block;
    padding: 10px 30px;
    margin: 20px auto;
    background: #FFF;
    border: 1px solid var(--section1-foreground);
    border-radius: 0.3em;
    color: var(--section1-foreground);
    font-weight: bold;
    font-size: 0.8em;
}