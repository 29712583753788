* {
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
    font-size: 1.2em;
    color: #FFF;
    text-align: center;
    line-height: 1.375em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}