footer {
    padding: 1% 0;
    background-color: #FFF;
    color: #9B2800;
    width: 100%;
}

footer a {
    margin: 0 10px;
    color: #9B2800;
    text-decoration: underline;
}

footer a:hover {
    color: #C53200;
    text-decoration: none;
}