.privateentries-list {
    list-style: none;
    padding: 0;
}

.privateentries-item {
    border: 1px dotted var(--section2-foreground);
    border-radius: 5px;
    margin: 1em auto;
    width: 90%;
    text-align: left;
    padding: 10px;
}

.privateentries-content {
    font-weight: bold;
}

.privateentries-date {
    display: block;
    text-align: right;
    padding: 0 5px;
    font-style: italic;
    font-size: 0.8em;
}

.privateentries-tags {
    display: block;
    text-align: left;
    padding: 0 5px;
    font-style: italic;
    font-size: 0.8em;
}

.addbutton {
    background: #FFF;
    border: 1px solid var(--section2-foreground);
    border-radius: 0.3em;
    margin: 5% auto;
    padding: 2% 10%;
    color: var(--section2-foreground);
    font-weight: bold;
    font-size: 1.625em;
    min-width: 10%;
}